import { Copyright } from "@/components/copyright";
import { FormError } from "@/components/form";
import GlobalLoading from "@/components/global-loading";
import GlobalSmallLoading from "@/components/global-small-loading";
import LoadingButton from "@/components/loading-button";
import { ListeCustomDomainsQuery } from "@/generated/graphql/graphql";
import { findAndTranslateErrors } from "@/helpers/format";
import { getParams, rawURLReplace } from "@/helpers/navigation";
import { Link } from "@/interfaces";
import NotFound from "@/pages/not-found";
import { GET_MY_WORKSPACE } from "@/preflight";
import { lightPageBlockStyle } from "@/styles/styles";
import { gql, useMutation, useQuery } from "@apollo/client";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LIST_WORKSPACE_DOMAINS } from "../../dashboard/settings";

const CREATE_LINK = gql`
  mutation CreateLink(
    $destination: String!
    $name: String
    $slug: String
    $customDomainID: UUID
  ) {
    CreateLink(
      destination: $destination
      name: $name
      slug: $slug
      customDomainID: $customDomainID
    ) {
      id
    }
  }
`;

export const CHECK_SLUG = gql`
  query CheckSlug($slug: String!, $customDomainID: UUID) {
    CheckSlug(slug: $slug, customDomainID: $customDomainID)
  }
`;

export function CreatePrimaryLink({
  name,
  destination,
  slug,
  customDomainID,
  setLink,
  setError,
  disabled,
}) {
  const { t } = useTranslation("workspace");

  const [mutationCreateLink, { data, loading, error }] =
    useMutation(CREATE_LINK);

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    const variables = { destination, slug, customDomainID }

    if (name) variables["name"] = name;

    mutationCreateLink({
      variables,
    });
  };

  useEffect(() => {
    if (error) {
      setError(findAndTranslateErrors({ error, t }));
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setLink(data.CreateLink);
    }
  }, [data]);

  return (
    <LoadingButton
      loading={loading}
      disabled={disabled}
      onClick={handleSubmit}
      text={t("links.create.submit")}
      fullWidth
    />
  );
}

export default function CreateLink() {
  const { t } = useTranslation(["workspace", "misc"]);
  const params = getParams();

  const [error, setError] = useState<string>("");
  const [link, setLink] = useState<Link | null>();
  const [workspace, setWorkspace] = useState<{
    id: null | string;
    slug: null | string;
    name: null | string;
  }>({ id: null, slug: null, name: null });
  const [customDomains, setCustomDomains] = useState<
    ListeCustomDomainsQuery["ListCustomDomains"]
  >([]);
  const [selectedCustomDomain, setSelectedCustomDomain] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [slug, setSlug] = useState<string>("");
  const [destination, setLinkDestination] = useState<string>(
    params.get("destination") || ""
  );
  const [primary, setPrimary] = useState<boolean>(false);
  const getMyWorkspace = useQuery(GET_MY_WORKSPACE);
  const listWorkspacesDomains = useQuery<ListeCustomDomainsQuery>(
    LIST_WORKSPACE_DOMAINS
  );

  useEffect(() => {
    if (getMyWorkspace.data) {
      if (workspace.id === null) setWorkspace(getMyWorkspace.data.MyWorkspace);
    }
  }, [getMyWorkspace]);

  useEffect(() => {
    if (listWorkspacesDomains.data) {
      setCustomDomains(listWorkspacesDomains.data.ListCustomDomains);
    }
  }, [listWorkspacesDomains]);

  useEffect(() => {
    if (customDomains && customDomains.length !== 0) {
      setSelectedCustomDomain(customDomains[0]?.id);
    } else {
      setSelectedCustomDomain("default");
    }
  }, [customDomains]);

  useEffect(() => {
    if (name === "" && workspace.name) {
      const params = getParams();
      if (params.get("name")) {
        setName(String(params.get("name")));
      }
      if (params.get("slug")) {
        setSlug(String(params.get("slug")));
      }
    }
  }, [workspace]);

  useEffect(() => {
    const params = getParams();
    setPrimary(String(params.get("primary")) === "true");
  }, []);

  const customDomainID =
    selectedCustomDomain === "default" ? null : selectedCustomDomain;

  const { data: slugData, error: slugError } = useQuery(CHECK_SLUG, {
    variables: { slug, customDomainID },
    skip: !slug,
  });

  const [slugTaken, setSlugTaken] = useState<boolean>(false);

  useEffect(() => {
    if (slugData) {
      setSlugTaken(!slugData.CheckSlug);
    }
  }, [slugData, slugError]);

  useEffect(() => {
    if (!slug) {
      setSlugTaken(false);
    }
  }, [slug]);

  // I had to actually make a whole "link" state because inside the component
  // it would crash completely and have infinite loop redirect on iPad
  if (link) {
    rawURLReplace(`/workspace/links/${link.id}/design?primary=${primary}`);
    return <GlobalSmallLoading />;
  }

  if (getMyWorkspace.loading || !workspace) return <GlobalLoading />;
  if (getMyWorkspace.error) {
    return <NotFound error={getMyWorkspace.error} />;
  }

  let wsName: string | null = workspace.name;
  if (!wsName) {
    wsName = "your workspace";
  }

  let title: React.ReactElement;
  let subtitle: React.ReactElement;
  if (primary) {
    title = (
      <Typography component="h1" variant="h5">
        {t("links.create.set-primary-basic-title", {
          name: wsName,
        })}
      </Typography>
    );
    subtitle = (
      <Typography variant="subtitle1" className="m-auto text-center">
        {t("links.create.set-primary-basic-description")}
      </Typography>
    );
  } else {
    title = (
      <Typography component="h1" variant="h5" className="m-auto text-center">
        {t("links.create.set-basic-title", { name: wsName })}
      </Typography>
    );

    subtitle = (
      <Typography variant="subtitle1" className="m-auto text-center">
        {t("links.create.set-basic-description")}
      </Typography>
    );
  }

  const formCompleted = destination.length > 0;
  const disabled = !formCompleted;

  const enableCustomDomains = customDomains && customDomains.length !== 0;

  return (
    <React.Fragment>
      <Container component="main" maxWidth="sm" className={lightPageBlockStyle}>
        <Box
          sx={{
            margin: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <QrCodeIcon />
          </Avatar>
          {title}
          {subtitle}
          <Box component="form" noValidate sx={{ mt: 3, width: "100%" }}>
            <FormError error={error} sx={{ mb: 3 }} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="destination"
                  value={destination}
                  onChange={(e) => setLinkDestination(e.target.value)}
                  required
                  fullWidth
                  id="linkDestination"
                  placeholder="https://example.com"
                  autoComplete="off"
                  label={t("links.create.link-destination")}
                  autoFocus
                  className="bg-white"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  id="linkName"
                  autoComplete="off"
                  label={t("links.create.link-name")}
                  className="bg-white"
                />
              </Grid>
              <Grid item xs={5}>
                <Tooltip
                  title={
                    !enableCustomDomains
                      ? "Create a custom domain from the dashboard to select it here"
                      : ""
                  }
                >
                  {(customDomains && (
                    <TextField
                      select
                      value={selectedCustomDomain || customDomains[0]?.id}
                      onChange={(e) => {
                        setSelectedCustomDomain(e.target.value);
                      }}
                      name="customDomain"
                      fullWidth
                      disabled={!enableCustomDomains}
                      autoComplete="off"
                      className="bg-white"
                      SelectProps={{
                        native: true,
                      }}
                    >
                      {customDomains.map((domain) => (
                        <option key={domain?.id} value={domain?.id || ""}>
                          {domain?.name}
                        </option>
                      ))}
                      <option key={"default"} value="default">
                        {import.meta.env.VITE_API_SHORT_RAW_DOMAIN}
                      </option>
                    </TextField>
                  )) || <></>}
                </Tooltip>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  name="slug"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                  fullWidth
                  id="linkSlug"
                  autoComplete="off"
                  error={slugTaken}
                  placeholder="my-short-link"
                  className="bg-white"
                  label={t("links.create.link-slug")}
                />
                <p className="text-red text-sm">
                  {slugTaken ? "This shortlink is already taken." : ""}
                </p>
              </Grid>
              <Grid item xs={12}>
                <CreatePrimaryLink
                  name={name}
                  destination={destination}
                  slug={slug}
                  customDomainID={customDomainID}
                  disabled={disabled}
                  setError={setError}
                  setLink={setLink}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Copyright sx={{ mt: 5, mb: 5 }} />
    </React.Fragment>
  );
}
