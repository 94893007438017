import * as Sentry from "@sentry/react";

export function sleep(delay) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

export function clickOnURL(url, fileName) {
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function linkFileName(link): string {
  const fileName = link.slug ? `${link.slug}-${link.shortID}` : link.shortID;

  console.log(link);
  console.log("fileName: ", fileName);
  return fileName;
}

export async function downloadImage(image, fileName) {
  if (!image) {
    return;
  }

  try {
    // image is a URL, i want to add the query string "content=attachment" to it no matter what the url is
    const imageAttachment = new URL(image);
    imageAttachment.searchParams.set("content", "attachment");
    image = imageAttachment.toString();

    // Fetch the image data from the URL
    const response = await fetch(image);
    if (!response.ok) {
      // Fallback to the original URL
      clickOnURL(image, fileName);
      Sentry.captureException("Fetch on download QR Code failed");
    }

    // Convert the response to a Blob
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    // Create a link and force download
    clickOnURL(url, fileName);

    // Revoke the Blob URL to free up memory
    URL.revokeObjectURL(url);
  } catch (error) {
    // Fallback to the original URL
    clickOnURL(image, fileName);
    console.error("Error downloading the image: ", error);
    Sentry.captureException(error);
  }
}

