import { Copyright } from "@/components/copyright";
import GlobalSmallLoading from "@/components/global-small-loading";
import { assignPathWith, getParams } from "@/helpers/navigation";
import NotFound from "@/pages/not-found";
import { GET_MY_WORKSPACE } from "@/preflight";
import { lightPageBlockStyle } from "@/styles/styles";
import { useQuery } from "@apollo/client";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { Chip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function MemberIndex() {
  const { t } = useTranslation("workspace");
  const navigate = useNavigate();

  const [primary, setPrimary] = useState<boolean>(false);
  const [workspace, setWorkspace] = useState({
    id: "",
    name: "",
  });
  const myWorkspace = useQuery(GET_MY_WORKSPACE);

  useEffect(() => {
    const params = getParams();
    setPrimary(String(params.get("primary")) === "true");
  }, []);

  // For now de prefer to force the primary link to be basic
  // There's still the option to go to the moving link.
  useEffect(() => {
    if (primary) {
      assignPathWith(navigate, `/workspace/links/create?primary=${primary}`);
    }
  }, [primary]);

  if (myWorkspace.loading) return <GlobalSmallLoading />;
  if (myWorkspace.data) {
    if (workspace.id === "") setWorkspace(myWorkspace.data.MyWorkspace);
  }
  if (myWorkspace.error) return <NotFound error={myWorkspace.error} />;

  return (
    <React.Fragment>
      <Container component="main" maxWidth="xs" className={lightPageBlockStyle}>
        <Box
          sx={{
            margin: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <QrCodeIcon />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ textAlign: "center" }}>
            {primary
              ? t("links.create.title-primary", { name: workspace.name })
              : t("links.create.title", { name: workspace.name })}
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    assignPathWith(
                      navigate,
                      `/workspace/links/create?primary=${primary}`
                    );
                  }}
                  size="large"
                  sx={{ mt: 2, p: 3, textTransform: "none" }}
                >
                  <QrCodeIcon />
                  <Trans t={t} i18nKey="links.create.basic-button">
                    {" "}
                    <br />{" "}
                  </Trans>
                </Button>
              </Grid>
              <Grid item xs={12} className="relative">
                <Chip
                  label="COMING SOON"
                  color="error"
                  className="absolute -bottom-3 -right-3 z-20"
                />
                <Button
                  disabled={true}
                  onClick={() => {
                    assignPathWith(
                      navigate,
                      `/workspace/links/create/moving?primary=${primary}`
                    );
                  }}
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{ mt: 2, p: 3, textTransform: "none" }}
                >
                  <AutoFixHighIcon />
                  <Trans t={t} i18nKey="links.create.copy-button">
                    {" "}
                    <br />{" "}
                  </Trans>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Copyright sx={{ mt: 5, mb: 5 }} />
    </React.Fragment>
  );
}
