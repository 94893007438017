// import { useTranslation } from "react-i18next";
import { gql, useQuery } from "@apollo/client";
import { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GlobalSmallLoading from "./components/global-small-loading";
import { sleep } from "./helpers";
import { Permission, Resource, permitAccess } from "./helpers/access";
import { eventBusEmit, eventBusReceive } from "./helpers/event-bus";
import { memberName } from "./helpers/format";
import {
  clearIdentity,
  getLanguage,
  getRole,
  isGuest,
  setIdentity,
  setLanguage,
  withoutDashboardAccess,
} from "./helpers/identity";
import { currentRoute, getParams, rawURLReplace } from "./helpers/navigation";
import PageError from "./pages/error";

const PING_ATTEMPTS = 3;
const PING_FREQUENCY = 200;

const PING = gql`
  query Ping {
    Ping
  }
`;

export const GET_MY_WORKSPACE = gql`
  query MyWorkspace {
    MyWorkspace {
      id
      name
      subscriptionType
    }
  }
`;

const GET_MYSELF = gql`
  query GetMyselfWithWorkspace {
    GetMyself {
      id
      role
      email
      firstName
      lastName
      password
      displayLanguage
      workspace {
        id
        subscriptionType
        createdAt
      }
    }
  }
`;

const GET_ORGANIZATION_METRIC = gql`
  query GetWorkspaceMetricMonthlyEvents {
    GetWorkspaceMetric {
      totalMonthlyEvents
    }
  }
`;

export default function Preflight({ children }) {
  const { t, i18n } = useTranslation("misc");
  // before sending anything we set a base setLanguage
  // this will also set i18n.language and allow us to have at all time
  // within our system and communication with the API.
  if (!getLanguage()) setLanguage("system", i18n);

  const getPing = useQuery(PING, {
    notifyOnNetworkStatusChange: true,
  });
  const [crash, setCrash] = useState<number>(0);
  const params = getParams();

  const getGetMyself = useQuery(GET_MYSELF, {
    // for now we only get the myself member to retrieve
    // the language if not defined, in the future we might want
    // to go further and define window.myself
    skip: isGuest(),
  });
  const getWorkspaceMetric = useQuery(GET_ORGANIZATION_METRIC, {
    // since we don't get any ID it's better
    // to just avoid caching on this one
    fetchPolicy: "no-cache",
    skip: isGuest() || withoutDashboardAccess(getRole()),
  });

  // sometimes apollo crashes with "Load failed"
  // which has nothing to do with the network or server itself
  useEffect(() => {
    if (getPing.error?.message === "Failed to fetch") {
      setCrash(crash + 1);

      (async () => {
        await sleep(PING_FREQUENCY);
        if (crash < PING_ATTEMPTS) getPing.refetch();
      })();
    }
  }, [getPing]);

  useEffect(() => {
    if (getGetMyself.data) {
      const myself = getGetMyself.data.GetMyself;
      // Crisp data agregation
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window.Beacon && myself.email) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.Beacon("identify", {
          email: myself.email,
          name: memberName(myself),
        });
      }
    }
  }, [getGetMyself.data]);

  useEffect(() => {
    if (getGetMyself.data) {
      const myself = getGetMyself.data.GetMyself;
      if (
        myself.workspace.subscriptionType &&
        myself.workspace.subscriptionType !== "FREE_FOREVER" &&
        myself.workspace.subscriptionType !== "FREE_TRIAL" &&
        permitAccess({
          role: getRole(),
          resource: Resource.BILLING,
          permission: Permission.UPDATE,
        })
      ) {
        // We have to use event buses to change the menu
        // Because it's too complicated to get down the layouts
        eventBusReceive("menu-ready", () => {
          eventBusEmit({ type: "menu-items", payload: "show-billing" });
        });
        eventBusEmit({ type: "menu-items", payload: "show-billing" });
      } else {
        eventBusReceive("menu-ready", () => {
          eventBusEmit({ type: "menu-items", payload: "show-upgrade" });
        });
        eventBusEmit({ type: "menu-items", payload: "show-upgrade" });
      }
      if (myself.displayLanguage) {
        setLanguage(myself.displayLanguage, i18n);
      }
    }
  }, [getGetMyself.data]);

  if (getGetMyself.data) {
    const myself = getGetMyself.data.GetMyself;
    // We refresh the identity stored on each preflight
    // In case roles or permissions change
    setIdentity(myself);

    const createdAt = new Date(myself.workspace.createdAt);
    const workspaceIsNotFresh =
      createdAt < new Date(Date.now() - 1 * 60 * 1000);

    if (
      !myself.email &&
      workspaceIsNotFresh &&
      !currentRoute("/myself/credentials") &&
      !params.get("skip")
    ) {
      rawURLReplace("/myself/credentials");
      // we show nothing because
      // it'll redirect the page directly
      return <GlobalSmallLoading />;
    }
  }

  if (getPing.loading || (getPing.error && crash <= PING_ATTEMPTS)) {
    return <GlobalSmallLoading />;
  }

  // sometimes, ping can be faulty
  // it happens on workspace sign-up sometimes and will break the flow
  // a tolerance of 3 seems fair on network issues.
  if (crash >= PING_ATTEMPTS) {
    clearIdentity();
    return <PageError error={t("error.ping")} />;
  }

  return <Suspense>{children}</Suspense>;
}
