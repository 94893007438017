import { capitalizeFirst } from "@/helpers/format";
import { assignPathWith } from "@/helpers/navigation";
import { relativeDate } from "@/helpers/time";
import { tableContainerSx } from "@/theme";
import EditIcon from "@mui/icons-material/Edit";
import {
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import * as React from "react";
import { useTranslation } from "react-i18next";

export function Name({ member }) {
  if (member.firstName === null && member.lastName === null) return "-";
  const fullName = `${member.firstName || ""} ${member.lastName || ""}`.trim();

  if (member.accessRevokedAt) {
    return <div style={{ textDecoration: "line-through" }}>{fullName}</div>;
  }

  return fullName;
}

export function ChipRole({ role }) {
  const { t } = useTranslation("workspace");

  switch (role) {
    case "ADMIN":
      return <Chip label={t("members.list.admin-role")} color="primary" />;
    case "VIEWER":
      return <Chip label={t("members.list.viewer-role")} color="secondary" />;
    default:
      return <></>;
  }
}

export function List({ members }) {
  const { i18n, t } = useTranslation("workspace");

  const columns: GridColDef[] = [
    {
      field: "name",
      valueGetter: (_, row) => {
        return `${row.firstName} ${row.lastName}`;
      },
      flex: 1,
      minWidth: 150,
      headerName: t("members.list.name"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return (
          <SensitiveData fakeType="name">
            <Name member={params.row} />
          </SensitiveData>
        );
      },
    },
    {
      field: "role",
      flex: 1,
      minWidth: 150,
      headerName: t("members.list.role"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return <ChipRole role={params.row.role} />;
      },
    },
    {
      field: "email",
      flex: 1,
      minWidth: 150,
      headerName: t("members.list.email"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return (
          <SensitiveData fakeType="email">{params.row.email}</SensitiveData>
        );
      },
    },
    {
      field: "last-sign-in",
      flex: 1,
      minWidth: 150,
      headerName: t("members.list.last-sign-in"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        if (!params.row.lastSigninAt) return "-";

        return (
          <>{capitalizeFirst(relativeDate(params.row.lastSigninAt, i18n))}</>
        );
      },
      valueGetter: (_, row) => {
        return relativeDate(row.createdAt, i18n);
      },
    },
    {
      field: "registration-date",
      flex: 1,
      minWidth: 150,
      headerName: t("members.list.registration-date"),
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return <>{capitalizeFirst(relativeDate(params.row.createdAt, i18n))}</>;
      },
      valueGetter: (_, row) => {
        return relativeDate(row.createdAt, i18n);
      },
    },
    {
      field: "actions",
      flex: 0.6,
      minWidth: 150,
      headerName: "Actions",
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, Member>) => {
        return <ActionMore member={params.row} />;
      },
    },
  ];

  const rows = members;
  return <GridWithSearch columns={columns} rows={rows} i18n={i18n} />;
}

import { GridWithSearch } from "@/components/data-table";
import { SensitiveData } from "@/components/sensitive-data";
import { Permission, Resource, permitAccess } from "@/helpers/access";
import { getRole } from "@/helpers/identity";
import { Member } from "@/interfaces";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

export function ActionMore({ member }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation("workspace");

  return (
    <React.Fragment>
      <IconButton
        color="primary"
        aria-label={t("members.list.team-members-metrics-button")}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        keepMounted
      >
        <MenuActionDevices member={member} />
        <MenuActionEdit member={member} />
      </Menu>
    </React.Fragment>
  );
}

export function LinkSelector({ links, link, setLink }) {
  const handleChange = (event) => {
    const selectedLink = links.find((z) => z.id === event.target.value);
    setLink(selectedLink);
  };

  return (
    <Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={{ mb: 2 }}>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="links"
          name="links"
          value={link.id}
          onChange={handleChange}
        >
          {links.map((currentLink) => (
            <FormControlLabel
              key={currentLink.id}
              value={currentLink.id}
              control={<Radio />}
              label={currentLink.name} // Assuming each link has a name property
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
}

import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import { useNavigate } from "react-router-dom";

export function MenuActionDevices({ member, searchDate = "" }) {
  const navigate = useNavigate();
  const { t } = useTranslation("workspace");

  return (
    <React.Fragment>
      <MenuItem
        // lastKnownIP being present typically means there are devices registered
        disabled={!member.lastKnownIP}
        onClick={() => {
          assignPathWith(
            navigate,
            `/workspace/dashboard/members/${member.id}/devices`
          );
        }}
      >
        <ListItemIcon>
          <AddToHomeScreenIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          {t("members.list.team-members-devices-button")}
        </ListItemText>
      </MenuItem>
    </React.Fragment>
  );
}

export function MenuActionEdit({ member }) {
  const { t } = useTranslation("workspace");
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <MenuItem
        disabled={
          !permitAccess({
            role: getRole(),
            resource: Resource.DASHBOARD,
            permission: Permission.UPDATE,
          })
        }
        onClick={() => {
          assignPathWith(
            navigate,
            `/workspace/dashboard/members/${member.id}/edit`
          );
        }}
      >
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          {t("members.list.team-members-edit-button")}
        </ListItemText>
      </MenuItem>
    </React.Fragment>
  );
}

export default function MembersList({ members }) {
  return (
    <React.Fragment>
      <TableContainer
        component={Paper}
        sx={tableContainerSx}
        className="border border-solid border-greyish shadow-none"
      >
        <List members={members} />
      </TableContainer>
    </React.Fragment>
  );
}
