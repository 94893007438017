import { Copyright } from "@/components/copyright";
import { FormError, FormSuccess } from "@/components/form";
import LoadingButton from "@/components/loading-button";
import { getParams, rawURLAssign } from "@/helpers/navigation";
import { lightPageBlockStyle } from "@/styles/styles";
import { gql, useMutation } from "@apollo/client";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $newPassword: String!) {
    ResetPassword(token: $token, newPassword: $newPassword)
  }
`;

export default function ResetPassword() {
  const { t } = useTranslation("authentication");
  const params = getParams();

  const [newPassword, setNewPassword] = useState<string>("");
  const [mutationRequestPasswordReset, { data, loading, error }] =
    useMutation(RESET_PASSWORD);

  const token = params.get("token") || "";

  const formCompleted = newPassword.length > 0;
  const disabled = !formCompleted;

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    mutationRequestPasswordReset({
      variables: {
        token,
        newPassword,
      },
    });
  };

  if (data) {
    // self-redirect
    setTimeout(() => {
      rawURLAssign("/authentication/sign-in");
    }, 2000);
  }

  return (
    <React.Fragment>
      <Container component="main" maxWidth="xs" className={lightPageBlockStyle}>
        <Box
          sx={{
            margin: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("reset-password.title")}
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <FormError error={error} sx={{ mb: 3 }} />
            {data ? (
              <FormSuccess
                message="Reset password successful. You'll be redirected to sign-in."
                sx={{ mb: 3 }}
              />
            ) : (
              <></>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  value={newPassword}
                  autoFocus
                  onChange={(event) => {
                    setNewPassword(event.target.value);
                  }}
                  label={t("reset-password.new-password")}
                  type="password"
                  id="password"
                  autoComplete="off"
                  className="bg-white"
                />
              </Grid>
            </Grid>
            <LoadingButton
              loading={loading}
              disabled={disabled}
              onClick={handleSubmit}
              text={t("reset-password.submit")}
              fullWidth
            />
          </Box>
        </Box>
      </Container>
      <Copyright sx={{ mt: 5, mb: 5 }} />
    </React.Fragment>
  );
}
