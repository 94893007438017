import BarChartIcon from "@mui/icons-material/BarChart";
import CreditCardIcon from "@mui/icons-material/CreditCard";
// import DashboardIcon from "@mui/icons-material/Dashboard";
import { Permission, Resource, permitAccess } from "@/helpers/access";
import { eventBusEmit, eventBusReceive } from "@/helpers/event-bus";
import { getRole } from "@/helpers/identity";
import { assignPathWith } from "@/helpers/navigation";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import HelpIcon from "@mui/icons-material/Help";
import PeopleIcon from "@mui/icons-material/People";
import QrCodeIcon from "@mui/icons-material/QrCode";
import SettingsIcon from "@mui/icons-material/Settings";
import TodayIcon from "@mui/icons-material/Today";
import { Divider } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function NavigationMenu({ open }) {
  const { t } = useTranslation("workspace");
  const [showBilling, setShowBilling] = React.useState<boolean>(false);

  const showSettings = permitAccess({
    role: getRole(),
    resource: Resource.DASHBOARD,
    permission: Permission.UPDATE,
  });

  React.useMemo(() => {
    eventBusReceive("menu-items", (payload) => {
      if (payload === "show-billing") {
        setShowBilling(true);
      }
    });
    eventBusEmit({ type: "menu-ready", payload: true });
  }, []);

  return (
    <React.Fragment>
      <div className="flex flex-col p-4 gap-2 justify-center">
        <MenuItem
          icon={<TodayIcon fontSize="small" />}
          name={t("menu.live-events")}
          link="/workspace/dashboard/live"
          open={open}
        />
        <MenuItem
          icon={<PeopleIcon fontSize="small" />}
          name={t("menu.team-members")}
          link="/workspace/dashboard/members"
          open={open}
        />
        <MenuItem
          icon={<QrCodeIcon fontSize="small" />}
          name={t("menu.links")}
          link="/workspace/dashboard/links"
          open={open}
        />
        <Divider />
        <MenuItem
          icon={<BarChartIcon fontSize="small" />}
          name={t("menu.reports")}
          link="/workspace/dashboard/reports"
          open={open}
        />
        {showBilling ? (
          <MenuItem
            icon={<CreditCardIcon fontSize="small" />}
            name={t("menu.billing")}
            link="/workspace/billing"
            open={open}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="absolute bottom-0 left-0 w-full">
        <div className="flex flex-col p-4 gap-2 justify-center">
          {showSettings ? (
            <MenuItem
              icon={<SettingsIcon fontSize="small" />}
              name={t("menu.settings")}
              link="/workspace/dashboard/settings"
              open={open}
            />
          ) : (
            <></>
          )}
          <MenuItem
            icon={<HelpIcon fontSize="small" />}
            name={t("menu.support")}
            onClick={() => {
              // Crisp click
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (typeof window.Beacon !== "undefined") window.Beacon("open");
            }}
            link={"/#support"}
            open={open}
          />
          <Divider />
          <MenuItem
            icon={<ExitToAppIcon fontSize="small" />}
            name={t("menu.sign-out")}
            link="/sign-out"
            open={open}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export function MenuItem({
  icon,
  name,
  link,
  open,
  disabled = false,
  ...props
}) {
  const navigate = useNavigate();

  let hoverStyle = "hover:cursor-pointer";
  let iconStyle = "";
  if (!disabled) hoverStyle += " hover:bg-grey";
  if (link && window.location.href.includes(link)) {
    hoverStyle += " bg-grey";
    iconStyle = "text-primary";
  }

  let disabledStyle = "";
  if (disabled) disabledStyle += "opacity-30";

  return (
    <div
      className={`flex flex-row items-center ${hoverStyle} ${disabledStyle} md:p-2 pb-2 pt-2 rounded-lg`}
      onClick={() => {
        if (link) {
          assignPathWith(navigate, link);
        }
      }}
      {...props}
    >
      <div className={`flex align-center mr-3 ${iconStyle}`}>{icon}</div>
      <div>{open ? name : ""}</div>
    </div>
  );
}
