import Loading from "@/components/loading";
import { eventBusEmit, eventBusReceive } from "@/helpers/event-bus";
import Title from "@/pages/workspace/dashboard/components/title";
import Calendar from "@/pages/workspace/dashboard/live/calendar";
import Events from "@/pages/workspace/dashboard/live/events";
import { dialogStyle, tableContainerSx } from "@/theme";
import { gql, useQuery } from "@apollo/client";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import Metrics from "./metrics";
import Timeline from "./timeline";

import PartialError from "@/components/partial-error";
import { QrcodeImage } from "@/components/qr-code";
import { Transition } from "@/components/transition";
import { replacePathWith } from "@/helpers/navigation";
import { relativeDateFromDate } from "@/helpers/time";
import { Event, Myself, Workspace } from "@/interfaces";

export const APP_STORE_URL =
  "https://apps.apple.com/us/app/linkbreakers/id6615062652";
export const GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=io.loschcode.linkbreakers";

const GET_ORGANIZATION_METRIC = gql`
  query GetWorkspaceMetricTotals {
    GetWorkspaceMetric {
      totalMembers
      totalEvents
    }
  }
`;

const GET_MYSELF = gql`
  query GetMyselfWithWorkspaceAndPrimaryLink {
    GetMyself {
      id
      firstName
      lastName
      password
      workspace {
        id
        name
        primaryLink {
          id
          designType
          qrcodeSignedURL
        }
      }
    }
  }
`;

const LIST_EVENTS = gql`
  query ListEvents($startDate: Time!, $endDate: Time!) {
    ListEvents(startDate: $startDate, endDate: $endDate) {
      id
      scannedAt
      action
      triggeredBy
      entrypoint
      destination
      httpMethod
      link {
        id
        name
        destination
        designType
      }
      device {
        id
        deviceName
        deviceBrand
        deviceType
        platformName
        platformVersion
        browserName
        browserVersion
        browserLanguage
        country
        region
        state
        city
      }
    }
  }
`;

import { UpgradeButton } from "@/components/upgrade-button";
import { graphQLErrorCode } from "@/helpers/format";
import { getGlobal, setGlobal } from "@/helpers/identity";
import { currentPusher } from "@/pusher";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function GettingStartedInstructions({ workspace }) {
  const { t } = useTranslation("workspace");

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sx={{ px: 0 }}>
        <Grid container spacing={2}>
          {/* Step 1 */}
          <Grid item xs={12}>
            <Grid container spacing={2} marginTop={1}>
              <Grid item xs={12}>
                <Chip label="1" color="primary" size={"medium"} />
                <span style={{ paddingLeft: "0.5em", fontWeight: "bold" }}>
                  {t("getting-started.step-1")}
                </span>
              </Grid>
              <Grid container xs={8} spacing={2} marginLeft={5}>
                <Grid item xs={12}>
                  {t("getting-started.step-1-description")}
                </Grid>
              </Grid>
              <Grid container xs={8} spacing={2} marginLeft={3}>
                <Grid item xs={6}>
                  <QrcodeImage
                    image={workspace.primaryLink.qrcodeSignedURL}
                    fileName="qrcode"
                    size="preview"
                    clickToDownload={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Step 2 */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Chip label="2" color="primary" size={"medium"} />
                <span style={{ paddingLeft: "0.5em", fontWeight: "bold" }}>
                  {t("getting-started.step-2")}
                </span>
              </Grid>
              <Grid container xs={8} spacing={2} marginLeft={5}>
                <Grid item xs={12}>
                  {t("getting-started.step-2-description")}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function GettingStartedDialog({
  workspace,
  welcomeOpen,
  setWelcomeOpen,
}) {
  const { t } = useTranslation("workspace");

  return (
    <Dialog
      open={welcomeOpen}
      TransitionComponent={Transition}
      sx={dialogStyle}
      onClose={() => {
        setWelcomeOpen(false);
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>
        {t("getting-started.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t("getting-started.instructions")}
        </DialogContentText>
        <GettingStartedInstructions workspace={workspace} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setWelcomeOpen(false);
          }}
        >
          {t("getting-started.button")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const defaultCalendar = new Date();
const defaultStart = Object.assign(new Date(), defaultCalendar);
const defaultEnd = Object.assign(new Date(), defaultCalendar);
defaultStart.setHours(0, 0, 0, 0);
defaultEnd.setHours(23, 59, 59, 999);

export default function Live() {
  const { i18n, t } = useTranslation(["workspace", "misc"]);
  const [refetchEvents, setRefetchEvents] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    eventBusReceive("refetch-events", (payload) => {
      setRefetchEvents(payload);
    });
  }, []);

  let lastEventsPolling: Date = new Date();
  // TODO: this is useless and should be just put on the preflight and recovered directly
  const [, setMyself] = useState<Myself>({
    id: null,
    password: null,
    firstName: null,
    lastName: null,
  });
  const [workspace, setWorkspace] = useState<Workspace>({
    id: null,
    name: null,
    primaryLink: {
      id: null,
      qrcodeSignedURL: null,
      destination: null,
      designType: "BASIC",
    },
  });
  const [gettingStarted, setGettingStarted] = useState<boolean>(false);
  const [welcomeOpen, setWelcomeOpen] = useState<boolean>(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [calendar, setCalendar] = useState<dayjs.Dayjs | null>(null);
  const [dateRange, setDateRange] = useState({
    start: defaultStart,
    end: defaultEnd,
  });
  const getGetMyself = useQuery(GET_MYSELF);
  const listEvents = useQuery(LIST_EVENTS, {
    variables: { startDate: dateRange.start, endDate: dateRange.end },
    skip: !refetchEvents,
    onCompleted: () => {
      lastEventsPolling = new Date();
    },
    notifyOnNetworkStatusChange: true,
    // we never want to have it cached
    // it should always refetch since we are "live"
    fetchPolicy: "cache-and-network",
  });
  const getWorkspaceMetric = useQuery(GET_ORGANIZATION_METRIC, {
    skip: events.length > 0,
  });

  useEffect(() => {
    if (workspace.id) {
      const channel = currentPusher.subscribe(
        `linkbreakers.workspace.${workspace.id}`
      );
      channel.bind("linkbreakers.fct.event-created.0", function (data) {
        // const pushedEvent = JSON.stringify(data);
        // for now, we just refetch the events
        // because we detect an event was effectively added
        listEvents.refetch();
      });
    }
  }, [workspace]);

  useEffect(() => {
    eventBusEmit({ type: "page-name", payload: t("live-events.title") });
    eventBusEmit({ type: "right-menu", payload: <UpgradeButton /> });
  }, [t]);

  useEffect(() => {
    if (listEvents.data) {
      setEvents(listEvents.data.ListEvents);

      if (listEvents.data.ListEvents.length > 0) {
        setGettingStarted(false);
        setWelcomeOpen(false);
      }
    }
  }, [listEvents]);

  useEffect(() => {
    if (getGetMyself.data) {
      setMyself(getGetMyself.data.GetMyself);
      setWorkspace(getGetMyself.data.GetMyself.workspace);
    }
  }, [getGetMyself]);

  useEffect(() => {
    if (calendar) {
      // we use that to clone the date so it's dereferenced
      const date = calendar.toISOString(); // calendar.$d
      const start = new Date(date);
      const end = new Date(date);
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);

      setDateRange({
        start,
        end,
      });
    }
  }, [calendar]);

  useEffect(() => {
    if (getWorkspaceMetric.data) {
      if (getWorkspaceMetric.data.GetWorkspaceMetric.totalEvents === 0) {
        setGettingStarted(true);
      } else {
        // we want to refetch at least once
        // when we show for the first time the dashboard
        if (gettingStarted) {
          listEvents.refetch();
          setGettingStarted(false);
        }
      }
    }
  }, [getWorkspaceMetric.data, listEvents]);

  useEffect(() => {
    if (
      gettingStarted &&
      getGetMyself.data &&
      !getGlobal("welcome-shown-once")
    ) {
      setWelcomeOpen(true);
      setGlobal("welcome-shown-once", true);
    }
  }, [gettingStarted, getGetMyself]);

  if (getGetMyself.loading) {
    return <Loading />;
  }

  if (getGetMyself.error) {
    if (graphQLErrorCode(getGetMyself.error, "LINK_QRCODE_NOT_FOUND")) {
      replacePathWith(navigate, "/workspace/links/create?primary=true");
      return <Loading />;
    }

    return (
      <PartialError error={t("error.page-data-failure", { ns: "misc" })} />
    );
  }

  if (listEvents.error) {
    return (
      <PartialError error={t("error.page-data-failure", { ns: "misc" })} />
    );
  }

  return (
    <Grid container spacing={3}>
      {/* Getting Started */}
      <GettingStartedDialog
        workspace={workspace}
        welcomeOpen={welcomeOpen}
        setWelcomeOpen={setWelcomeOpen}
      />
      {/* Timeline */}
      <Grid item xs={12} lg={8}>
        <Title>{t("timeline.title")}</Title>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 380,
          }}
          className="border border-solid border-greyish shadow-none"
        >
          <Timeline dateRange={dateRange} workspace={workspace} />
        </Paper>
      </Grid>
      {/* Calendar */}
      <Grid item xs={12} lg={4}>
        <Title>{t("calendar.title")}</Title>
        <TableContainer
          component={Paper}
          className="border border-solid border-greyish shadow-none"
          sx={{ p: 2, display: "flex", flexDirection: "column", height: 380 }}
        >
          <Calendar
            defaultCalendar={defaultCalendar}
            setCalendar={setCalendar}
            // we don't want to put a loading state to the calendar once it's done once on the page
            // otherwise refetch of listEvents will cause a scroll jump (because it's somehow a complet MUI Component)
            loading={listEvents.loading && !listEvents.data}
          />
        </TableContainer>
      </Grid>
      {/* Events */}
      <Grid item xs={12}>
        <Title>{t("events.title")}</Title>
        <TableContainer
          component={Paper}
          className="border border-solid border-greyish shadow-none"
          sx={tableContainerSx}
        >
          <Events events={events} />
        </TableContainer>
        <Typography
          component="p"
          sx={{ mt: 2, mr: 1, color: "grey", textAlign: "right" }}
        >
          {t("events.last-data-refresh", {
            date: relativeDateFromDate(lastEventsPolling, i18n),
          })}
        </Typography>
      </Grid>
      {/* Metrics */}
      <Grid item xs={12}>
        <Title>{t("metrics.title")}</Title>
        <Metrics dateRange={dateRange} workspace={workspace} />
      </Grid>
    </Grid>
  );
}
