import { Copyright } from "@/components/copyright";
import { getParams, rawURLReplace } from "@/helpers/navigation";
import GestureIcon from "@mui/icons-material/Gesture";
import { Avatar, Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { QRCodeDesign } from "../../dashboard/links/edit/design";

export default function DesignLink() {
  const { linkID } = useParams();
  const [primary, setPrimary] = useState<boolean>(false);

  useEffect(() => {
    const params = getParams();
    setPrimary(String(params.get("primary")) === "true");
  }, []);

  const callback = () => {
    if (primary) {
      rawURLReplace("/workspace/dashboard/live");
    } else {
      rawURLReplace("/workspace/dashboard/links");
    }
  };

  return (
    <React.Fragment>
      <Container
        component="main"
        maxWidth="lg"
        className="lg:bg-white lg:border lg:border-solid lg:border-greyinput rounded-xl mt-10"
      >
        <Box
          sx={{
            margin: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <GestureIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Design QR Code
          </Typography>
          <Typography variant="subtitle1" className="m-auto text-center">
            Keep a classic style or make it your own.
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3, width: "100%" }}>
            <Grid spacing={2}>
              <QRCodeDesign
                linkID={linkID}
                style="lean"
                callback={callback}
                newDesign={true}
              />
            </Grid>
          </Box>
        </Box>
      </Container>
      <Copyright sx={{ mt: 5, mb: 5 }} />
    </React.Fragment>
  );
}
