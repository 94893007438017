import { Copyright } from "@/components/copyright";
import { FormError, FormErrorComponent, FormWarning } from "@/components/form";
import GlobalSmallLoading from "@/components/global-small-loading";
import LoadingButton from "@/components/loading-button";
import PartialLoading from "@/components/partial-loading";
import { firebaseApp } from "@/firebase";
import { sleep } from "@/helpers";
import { getDeviceInfo } from "@/helpers/device";
import { graphQLErrorCode } from "@/helpers/format";
import { getClientOrigin, hasIdentity, setIdentity } from "@/helpers/identity";
import {
  addParamsToPath,
  assignPathWith,
  getParams,
  rawURLAssign,
} from "@/helpers/navigation";
import {
  GoogleContinueButton,
  MicrosoftContinueButton,
  OrDivider,
} from "@/pages/authentication/sign-in";
import { lightPageBlockStyle } from "@/styles/styles";
import { gql, useMutation } from "@apollo/client";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  getRedirectResult,
  signInWithRedirect,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SIGNUP = gql`
  mutation WorkspaceSignup(
    $identifier: String!
    $signupMethod: SignupMethod!
    $password: String
    $workspaceName: String
    $originInput: OriginInput!
    $deviceInput: DeviceInput!
  ) {
    WorkspaceSignup(
      identifier: $identifier
      signupMethod: $signupMethod
      password: $password
      workspaceName: $workspaceName
      originInput: $originInput
      deviceInput: $deviceInput
    ) {
      id
      email
      role
      accessToken
      workspace {
        id
      }
    }
  }
`;

export default function MemberSignUp() {
  return (
    <React.Fragment>
      <SignUpModule />;
      <Copyright sx={{ mb: 5 }} />
    </React.Fragment>
  );
}

export function SignUpModule() {
  const { t } = useTranslation("workspace");
  const navigate = useNavigate();

  const params = getParams();
  const [formData, setFormData] = useState({
    workspaceName: null,
    email: params.get("email") || "",
    password: "",
    terms_of_service: "",
  });
  const [termsOfService, setTermsOfService] = useState(false);
  const [mutationSignUp, { data, loading, error }] = useMutation(SIGNUP);
  const [firebaseError, setFirebaseError] = useState<string>("");
  const [signupLoading, setSignupLoading] = useState<boolean>(true);

  const googleSignUp = () => {
    const provider = new GoogleAuthProvider();
    // provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    provider.addScope("email");
    provider.addScope("profile");
    provider.setCustomParameters({
      login_hint: formData.email,
    });

    const auth = getAuth(firebaseApp);
    signInWithRedirect(auth, provider);
    setSignupLoading(true);
  };

  const microsoftSignUp = () => {
    const provider = new OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      login_hint: formData.email,
      prompt: "consent",
    });

    const auth = getAuth(firebaseApp);
    signInWithRedirect(auth, provider);
    setSignupLoading(true);
  };

  const handleRedirectResult = async () => {
    const auth = getAuth(firebaseApp);

    try {
      setSignupLoading(true);
      const result = await getRedirectResult(auth);

      if (result) {
        const tokenID = await result.user.getIdToken();
        const deviceInput = getDeviceInfo();
        const originInput = getClientOrigin();

        let workspaceName = null;
        if (formData.workspaceName) {
          workspaceName = formData.workspaceName;
        }

        await mutationSignUp({
          variables: {
            workspaceName,
            identifier: tokenID,
            signupMethod: "FIREBASE",
            deviceInput,
            originInput,
          },
        });
      } else {
        console.log(
          "No redirect result found. User may not have completed the sign-in."
        );
      }
    } catch (error) {
      setFirebaseError(
        "Something went wrong when trying to sign-in with social buttons. Please try again."
      );
    }
    setSignupLoading(false);
  };

  useEffect(() => {
    handleRedirectResult();
  }, []);

  useEffect(() => {
    if (data) {
      setIdentity({
        id: data.WorkspaceSignup.id,
        accessToken: data.WorkspaceSignup.accessToken,
        role: data.WorkspaceSignup.role,
        workspaceID: data.WorkspaceSignup.workspace.id,
      });

      (async () => {
        while (hasIdentity() !== true) await sleep(500);
        assignPathWith(navigate, "/workspace/links/create?primary=true");
      })();
    }
  }, [data]);

  if (data) {
    // we show nothing because
    // it'll redirect the page directly
    return <GlobalSmallLoading />;
  }

  const handleTermsOfService: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    handleChange(event);
    setTermsOfService(event.currentTarget.checked);
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    // we recover tall the data that could have been transmitted
    // from the landings if the person was on a landing
    const originInput = getClientOrigin();
    const deviceInput = getDeviceInfo();

    let workspaceName = null;
    if (formData.workspaceName) {
      workspaceName = formData.workspaceName;
    }

    mutationSignUp({
      variables: {
        workspaceName,
        identifier: formData.email,
        signupMethod: "EMAIL",
        password: formData.password,
        deviceInput,
        originInput,
      },
    });
  };

  const formCompleted =
    formData.email.length > 0 &&
    formData.password.length > 0 &&
    termsOfService === true;

  const disabled = !formCompleted || loading;

  let compError: JSX.Element = <></>;
  let hasCompError: boolean = false;
  if (error) {
    if (
      graphQLErrorCode(error, "EMAIL_ALREADY_TAKEN") ||
      graphQLErrorCode(error, "EMAIL_ALREADY_TAKEN_OTHER_ORGANIZATION")
    ) {
      hasCompError = true;

      const params = {
        email: formData.email,
        method: "email",
      };

      const path = addParamsToPath("/authentication/sign-in", params);

      compError = (
        <Trans t={t} i18nKey="sign-up.email-already-taken">
          text <a href={path}>text</a>
        </Trans>
      );
    } else if (graphQLErrorCode(error, "SOCIAL_EMAIL_NOT_VERIFIED")) {
      hasCompError = true;

      compError = (
        <>Please use an email that has been verified by the social provider.</>
      );
    }
  }

  const clientOrigin = getClientOrigin();
  let warning = "";
  if (clientOrigin.originOffer === "premium-plan") {
    warning =
      "Great news! You're eligible for a 2-week free trial. Sign up now to access all the premium features!";
  }

  if (signupLoading) {
    return (
      <Container component="main" maxWidth="xs" className={lightPageBlockStyle}>
        <Box
          sx={{
            margin: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Quick start
          </Typography>
          <Box
            component="form"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <PartialLoading />
          </Box>
        </Box>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="xs" className={lightPageBlockStyle}>
      <Box
        sx={{
          margin: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("sign-up.title")}
        </Typography>
        <Box component="form" noValidate sx={{ mt: 3 }}>
          {hasCompError ? (
            <FormErrorComponent error={compError} sx={{ mb: 3 }} />
          ) : (
            <FormError error={error || firebaseError} sx={{ mb: 3 }} />
          )}
          {warning ? <FormWarning message={warning} sx={{ mb: 3 }} /> : <></>}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                type="email"
                label={t("sign-up.email")}
                name="email"
                autoFocus
                value={formData.email}
                onChange={handleChange}
                autoComplete="email"
                className="bg-white"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                value={formData.password}
                onChange={handleChange}
                label={t("sign-up.password")}
                type="password"
                id="password"
                autoComplete="new-password"
                className="bg-white"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="workspace"
                name="workspaceName"
                value={formData.workspaceName}
                onChange={handleChange}
                fullWidth
                id="workspaceName"
                label={t("sign-up.workspace-name")}
                className="bg-white"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value="agreed"
                    name="terms_of_service"
                    color="primary"
                    onChange={handleTermsOfService}
                  />
                }
                label={
                  <Trans t={t} i18nKey="sign-up.terms-checkbox">
                    text{" "}
                    <a target="_blank" href="/legal/terms-of-service">
                      terms
                    </a>{" "}
                    text{" "}
                    <a target="_blank" href="/legal/privacy-statement">
                      privacy
                    </a>
                  </Trans>
                }
              />
            </Grid>
          </Grid>
          <LoadingButton
            loading={loading}
            disabled={disabled}
            onClick={handleSubmit}
            text={t("sign-up.submit")}
            fullWidth
          />
          <Grid container direction="column" className="mt-3">
            <div
              style={{
                textAlign: "center",
                paddingLeft: 16,
              }}
              className="text-sm"
            >
              Already have an account?{" "}
              <a
                href="#"
                onClick={() => {
                  const params = {
                    email: formData.email,
                  };

                  const path = addParamsToPath(
                    "/authentication/sign-in",
                    params
                  );
                  rawURLAssign(path);
                }}
              >
                Sign In
              </a>
            </div>
          </Grid>
        </Box>
        <OrDivider />
        <GoogleContinueButton onClick={googleSignUp} />
        <MicrosoftContinueButton onClick={microsoftSignUp} />
      </Box>
    </Container>
  );
}
