import { assignPathWith } from "@/helpers/navigation";
import { getDateAndTime } from "@/helpers/time";
import { EventsMetric, Report } from "@/interfaces";
import { tableContainerSx, tableHeadSx, tableSx } from "@/theme";
import { gql, useQuery } from "@apollo/client";
import BarChartIcon from "@mui/icons-material/BarChart";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableContainer,
  Tooltip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getSearchDate } from ".";

const GET_EVENTS_METRIC_PER_LINK = gql`
  query GetEventsMetricPerLink(
    $startDate: Time!
    $endDate: Time!
    $linkId: UUID
  ) {
    GetEventsMetric(startDate: $startDate, endDate: $endDate, linkId: $linkId) {
      totalEvents
      firstAt
      lastAt
    }
  }
`;

export function ActionMetrics({ link, searchDate = "" }) {
  const navigate = useNavigate();
  const { t } = useTranslation("workspace");

  let searchParams = "";
  // If it's custom, for now we just don't move it over
  // Because it'll not work since we don't have the exact dates
  if (searchDate && searchDate !== "custom") {
    searchParams = `?date_selector=${searchDate}`;
  }

  return (
    <Tooltip title={t("links.list.team-links-metrics-button")} placement="top">
      <IconButton
        color="primary"
        aria-label={t("links.list.team-links-metrics-button")}
        onClick={() => {
          assignPathWith(
            navigate,
            `/workspace/dashboard/links/${link.id}/metrics${searchParams}`
          );
        }}
      >
        <BarChartIcon />
      </IconButton>
    </Tooltip>
  );
}

export function List({ links, dateRange, setReports, reports }) {
  const { t } = useTranslation("workspace");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
  }, [dateRange]);

  let nothingToShow = <></>;

  if (links == null || links.length === 0 || reports.length === 0) {
    nothingToShow = (
      <React.Fragment>
        <TableRow key="0">
          <TableCell className="m-auto text-center border-b-0" colSpan={6}>
            {loading ? "Loading..." : t("links.list.nothing-to-show")}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Table key="0" size="small" sx={tableSx}>
        <TableHead>
          <TableRow key="0">
            <TableCell sx={tableHeadSx}>{t("links.list.qrcode")}</TableCell>
            <TableCell sx={tableHeadSx}>
              {t("links.metrics.first-at")}
            </TableCell>
            <TableCell sx={tableHeadSx}>{t("links.metrics.last-at")}</TableCell>
            <TableCell sx={tableHeadSx}>{t("links.metrics.events")}</TableCell>
            <TableCell sx={tableHeadSx}>{t("links.metrics.action")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {nothingToShow}
          {links
            ? links.map((link, idx) => (
                <TableLinkMetrics
                  key={idx}
                  link={link}
                  dateRange={dateRange}
                  setReports={setReports}
                  setLoading={setLoading}
                />
              ))
            : null}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

export function TableLinkMetrics({ link, dateRange, setReports, setLoading }) {
  const { t } = useTranslation("workspace");
  const searchDate = getSearchDate();

  const variables = {
    startDate: dateRange.start,
    endDate: dateRange.end,
    linkId: link.id,
  };

  const { data } = useQuery(GET_EVENTS_METRIC_PER_LINK, {
    variables,
    skip: !dateRange.start || !dateRange.end,
  });

  let firstAt = "";
  let lastAt = "";
  let totalEvents = 0;

  if (data) {
    const eventsMetric: EventsMetric = data.GetEventsMetric;
    firstAt = eventsMetric.firstAt;
    lastAt = eventsMetric.lastAt;
    totalEvents = eventsMetric.totalEvents;
    setLoading(false);
  }

  React.useEffect(() => {
    if (data) {
      const eventsMetric: EventsMetric = data.GetEventsMetric;
      if (eventsMetric.totalEvents === 0) {
        return;
      }
      // NOTE: this system replace the data received of the link each time we choose a new data
      // and it basically rebuild the reports
      setReports((prevData) => {
        // Filter out the existing item with the same link.id
        const filteredData = prevData.filter(
          (item) => item.link.id !== link.id
        );
        const payload: Report = { link, eventsMetric };
        // Add the new payload
        return [...filteredData, payload];
      });
    }
  }, [data]);

  if (totalEvents === 0) {
    return <></>;
  }

  return (
    <React.Fragment>
      <TableRow key={link.id}>
        <TableCell
          sx={{
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {link.name || link.destination}
        </TableCell>
        <TableCell>{firstAt ? getDateAndTime(firstAt) : "-"}</TableCell>
        <TableCell>{lastAt ? getDateAndTime(lastAt) : "-"}</TableCell>
        <TableCell>{totalEvents ? totalEvents : "-"}</TableCell>
        <TableCell>
          <ActionMore link={link} dateRange={dateRange} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ReportsList({ links, dateRange, setReports, reports }) {
  return (
    <React.Fragment>
      <TableContainer
        component={Paper}
        sx={tableContainerSx}
        className="border border-solid border-greyish shadow-none"
      >
        <List
          links={links}
          dateRange={dateRange}
          setReports={setReports}
          reports={reports}
        />
      </TableContainer>
    </React.Fragment>
  );
}

export function ActionMore({ link, dateRange }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (link: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(link.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton
        color="primary"
        aria-label="More"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        keepMounted
      >
        <MenuAction
          link={link}
          dateRange={dateRange}
          handleClose={handleClose}
        />
      </Menu>
    </React.Fragment>
  );
}

import { arrayToCSV, convertEventsToDataset, downloadCSV } from "@/helpers/csv";
import { slugify } from "@/helpers/format";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";

const LIST_EVENTS = gql`
  query ListEventsFromLink($linkId: UUID!, $startDate: Time!, $endDate: Time!) {
    ListEvents(linkId: $linkId, startDate: $startDate, endDate: $endDate) {
      id
      scannedAt
      action
      triggeredBy
      entrypoint
      destination
      httpMethod
      link {
        id
        name
        destination
        designType
      }
      device {
        id
        deviceName
        deviceBrand
        deviceType
        deviceMemory
        deviceConcurrency
        screenWidth
        screenHeight
        screenRatio
        GPU
        browserName
        browserVersion
        browserLanguage
        platformName
        platformVersion
        touchScreen
        networkConnection
        networkBandwidth
        networkRTT
        userAgent
        IPAddress
        applicationLanguage
        timezone
        country
        region
        state
        city
        lastUsedAt
      }
    }
  }
`;

export function MenuAction({ link, handleClose, dateRange }) {
  const { t } = useTranslation("workspace");
  const [downloadCsv, setDownloadCsv] = React.useState(false);
  const listEvents = useQuery(LIST_EVENTS, {
    variables: {
      linkId: link.id,
      startDate: dateRange.start,
      endDate: dateRange.end,
    },
    skip: !downloadCsv,
    fetchPolicy: "no-cache",
  });

  React.useEffect(() => {
    if (downloadCsv && listEvents.data) {
      const csv = convertEventsToDataset(t, listEvents.data.ListEvents);
      let linkName = "report";
      if (link.name && link.name.length > 0) {
        linkName = slugify(link.name);
      }
      downloadCSV({
        filename: `${linkName}-events`,
        data: arrayToCSV(csv),
      });
      setDownloadCsv(false);
    }
  }, [listEvents.data, downloadCSV]);

  return (
    <React.Fragment>
      <MenuItem
        onClick={() => {
          setDownloadCsv(true);
          handleClose();
        }}
      >
        <ListItemIcon>
          <SimCardDownloadIcon />
        </ListItemIcon>
        <ListItemText>{t("links.metrics.download-csv")}</ListItemText>
      </MenuItem>
    </React.Fragment>
  );
}
