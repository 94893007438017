import { capitalizeFirst, findAndTranslateErrors } from "@/helpers/format";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";

export function FormError({ error, ...props }) {
  const { t } = useTranslation("misc");

  const endErr = findAndTranslateErrors({ error, t });
  if (endErr === "") return <div></div>;

  return (
    <Alert severity="error" sx={{ paddingTop: 0, paddingBottom: 0 }} {...props}>
      {capitalizeFirst(endErr)}
    </Alert>
  );
}

export function FormErrorComponent({ error, ...props }) {
  if (!error?.props?.children?.length) return;
  return (
    <Alert severity="error" sx={{ paddingTop: 0, paddingBottom: 0 }} {...props}>
      {error}
    </Alert>
  );
}

export function FormWarning({ message, ...props }) {
  if (!message) return <div></div>;

  return (
    <Alert severity="warning" sx={{ paddingTop: 0, paddingBottom: 0 }} {...props}>
      {capitalizeFirst(message)}
    </Alert>
  );
}

export function FormInfo({ message, ...props }) {
  if (!message) return <div></div>;

  return (
    <Alert severity="info" sx={{ paddingTop: 0, paddingBottom: 0 }} {...props}>
      {capitalizeFirst(message)}
    </Alert>
  );
}

export function FormSuccess({ message, ...props }) {
  if (!message) return <div></div>;

  return (
    <Alert severity="success" sx={{ paddingTop: 0, paddingBottom: 0 }} {...props}>
      {capitalizeFirst(message)}
    </Alert>
  );
}
