import Loading from "@/components/loading";
import PartialError from "@/components/partial-error";
import { UpgradeButton } from "@/components/upgrade-button";
import { Permission, Resource, permitAccess } from "@/helpers/access";
import { eventBusEmit } from "@/helpers/event-bus";
import { getRole } from "@/helpers/identity";
import { LinkSetting, Workspace } from "@/interfaces";
import Title from "@/pages/workspace/dashboard/components/title";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  Tooltip,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

dayjs.extend(utc);

const VERIFY_DOMAIN_INTERVAL = 60000;

const GET_MY_WORKSPACE = gql`
  query MyWorkspaceWithSetting {
    MyWorkspace {
      id
      name
      linkSetting {
        id
        dataCollection
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation UpdateWorkspace($name: String) {
    UpdateWorkspace(name: $name) {
      id
      name
    }
  }
`;

const UPDATE_LINK_SETTING = gql`
  mutation UpdateLinkSetting($id: UUID!, $dataCollection: DataCollection) {
    UpdateLinkSetting(id: $id, dataCollection: $dataCollection) {
      id
    }
  }
`;

const CREATE_WORKSPACE_DOMAIN = gql`
  mutation CreateCustomDomain($name: String!) {
    CreateCustomDomain(name: $name) {
      id
    }
  }
`;

const DELETE_WORKSPACE_DOMAIN = gql`
  mutation DeleteCustomDomain($id: UUID!) {
    DeleteCustomDomain(id: $id)
  }
`;

const VERIFY_WORKSPACE_DOMAIN = gql`
  mutation VerifyCustomDomain($id: UUID!) {
    VerifyCustomDomain(id: $id) {
      id
      name
      verified
    }
  }
`;

export const LIST_WORKSPACE_DOMAINS = gql`
  query ListeCustomDomains {
    ListCustomDomains {
      id
      name
      verified
      lastCheckedAt
    }
  }
`;

export default function Dashboard() {
  const { t } = useTranslation(["workspace", "misc"]);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [updateWorkspaceSetting, setUpdateWorkspaceSetting] =
    useState<boolean>(false);

  const getMyWorkspace = useQuery(GET_MY_WORKSPACE);

  useEffect(() => {
    eventBusEmit({ type: "page-name", payload: t("settings.title") });
    eventBusEmit({ type: "right-menu", payload: <UpgradeButton /> });
  }, [t]);

  useEffect(() => {
    if (error) {
      eventBusEmit({ type: "form-error", payload: error });
      setError("");
      return;
    }

    if (success) {
      eventBusEmit({
        type: "form-success",
        payload: t("settings.edit.updated"),
      });
      setSuccess("");
    }
  }, [success, error]);

  if (getMyWorkspace.loading) return <Loading />;
  if (getMyWorkspace.error) {
    return (
      <PartialError error={t("error.page-data-failure", { ns: "misc" })} />
    );
  }

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    setUpdateWorkspaceSetting(true);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <WorkspaceSettings
            fetchedWorkspaceSetting={getMyWorkspace.data.MyWorkspace}
            setUpdateWorkspaceSetting={setUpdateWorkspaceSetting}
            updateWorkspaceSetting={updateWorkspaceSetting}
            setError={setError}
            setSuccess={setSuccess}
          />

          <Divider variant="middle" sx={{ mb: 2 }} />

          <Grid item xs={12} md={6} lg={3} sx={{ textAlign: "left" }}>
            <Button
              disabled={
                !permitAccess({
                  role: getRole(),
                  resource: Resource.DASHBOARD,
                  permission: Permission.UPDATE,
                })
              }
              type="submit"
              onClick={handleSubmit}
              variant="contained"
              sx={{ mb: 2 }}
            >
              {t("settings.submit")}
            </Button>
          </Grid>
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}

export function WorkspaceSettings({
  fetchedWorkspaceSetting,
  setUpdateWorkspaceSetting,
  updateWorkspaceSetting,
  setSuccess,
  setError,
}) {
  const { t } = useTranslation(["workspace", "misc"]);
  const [workspace, setWorkspace] = useState<Workspace>({
    id: null,
    name: null,
  });

  const [mutationUpdateWorkspace, { data, error }] =
    useMutation(UPDATE_ORGANIZATION);

  useEffect(() => {
    if (data) setSuccess(t("settings.edit.updated"));
    if (error) setError(error);
  }, [data, error]);

  useEffect(() => {
    if (fetchedWorkspaceSetting) setWorkspace(fetchedWorkspaceSetting);
  }, [fetchedWorkspaceSetting]);

  useEffect(() => {
    if (updateWorkspaceSetting) {
      mutationUpdateWorkspace({
        variables: {
          name: workspace.name,
        },
      });
      setUpdateWorkspaceSetting(false);
    }
  }, [updateWorkspaceSetting]);

  if (workspace.id === null) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Title>{t("workspace.title")}</Title>
      <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
        {t("workspace.description")}
      </Typography>

      <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <SettingDescription
            title={t("workspace.entity-details")}
            description={t("workspace.entity-details-description")}
          />
          <Grid item xs={12} md={8} sx={{ mb: 2, mt: 2 }}>
            <Paper
              sx={{ p: 2 }}
              className="border border-solid border-greyish shadow-none"
            >
              <Grid item xs={12}>
                <TextField
                  autoComplete="workspace"
                  name="workspaceName"
                  value={workspace.name}
                  onChange={(event) => {
                    setWorkspace((prevSet: Workspace) => ({
                      ...prevSet,
                      name: event.target.value,
                    }));
                  }}
                  required
                  id="workspaceName"
                  label={t("workspace.name-field")}
                />
              </Grid>
            </Paper>
          </Grid>
        </Stack>
      </Grid>
    </React.Fragment>
  );
}

export function LinkSettings({
  fetchedLinkSetting,
  setUpdateLinkSetting,
  updateLinkSetting,
  setSuccess,
  setError,
}) {
  const { t } = useTranslation(["workspace", "misc"]);
  const [linkSetting, setLinkSetting] = useState<LinkSetting>({
    id: null,
    dataCollection: "NONE",
  });
  const [mutationLinkSetting, { data, error }] =
    useMutation(UPDATE_LINK_SETTING);
  const { data: workspaceData } = useQuery(GET_MY_WORKSPACE);
  const freeAccount = !workspaceData?.MyWorkspace?.subscriptionType;

  useEffect(() => {
    if (data) setSuccess(t("settings.edit.updated"));
    if (error) setError(error);
  }, [data, error]);

  useEffect(() => {
    if (updateLinkSetting) {
      // we need to convert all the nulls data that were defined here
      // and send them  on the network as zero (numbers)
      // so it can nullified on the Golang side
      mutationLinkSetting({
        variables: linkSetting,
      });
      setUpdateLinkSetting(false);
    }
  }, [updateLinkSetting]);

  useEffect(() => {
    if (fetchedLinkSetting) {
      setLinkSetting(fetchedLinkSetting);
    }
  }, [fetchedLinkSetting]);

  if (linkSetting.id === null) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Title>{t("link-setting.title")}</Title>
      <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
        {t("links.description")}
      </Typography>

      <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <SettingDescription
            title={t("links.data-collection")}
            description={t("links.data-collection-description")}
            chip="GDPR"
          />
          <Grid item xs={12} md={8} sx={{ mb: 2, mt: 2 }}>
            <Paper
              sx={{ p: 2 }}
              className="border border-solid border-greyish shadow-none"
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Radio
                      checked={linkSetting.dataCollection === "NONE"}
                      onChange={() => {
                        setLinkSetting((prevSet: LinkSetting) => ({
                          ...prevSet,
                          dataCollection: "NONE",
                        }));
                        setUpdateLinkSetting(true);
                      }}
                    />
                  }
                  label={t("links.none-data-collection")}
                />
                <Typography component="p" sx={{ color: "grey" }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("links.none-data-collection-info", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  />
                </Typography>
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Radio
                      checked={linkSetting.dataCollection === "BASIC"}
                      onChange={() => {
                        setLinkSetting((prevSet: LinkSetting) => ({
                          ...prevSet,
                          dataCollection: "BASIC",
                        }));
                        setUpdateLinkSetting(true);
                      }}
                    />
                  }
                  label={t("links.basic-data-collection")}
                />
                <Typography component="p" sx={{ color: "grey" }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("links.basic-data-collection-info", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  />
                </Typography>
              </FormGroup>
              <FormGroup>
                <Tooltip
                  title="Advanced data collection is only available for premium users."
                  placement="left"
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={linkSetting.dataCollection === "ADVANCED"}
                        disabled={freeAccount}
                        onChange={() => {
                          setLinkSetting((prevSet: LinkSetting) => ({
                            ...prevSet,
                            dataCollection: "ADVANCED",
                          }));
                          setUpdateLinkSetting(true);
                        }}
                      />
                    }
                    label={t("links.advanced-data-collection")}
                  />
                </Tooltip>
                <Typography component="p" sx={{ color: "grey" }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("links.advanced-data-collection-info", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  />
                </Typography>
              </FormGroup>
            </Paper>
          </Grid>
        </Stack>
      </Grid>
    </React.Fragment>
  );
}

const ChipPremium = () => (
  <Tooltip title={t("workspace.gdpr")} placement="top">
    <span className="bg-primary rounded-lg text-sm p-1 text-white">
      PREMIUM
    </span>
  </Tooltip>
);

import { Transition } from "@/components/transition";
import { dialogStyle } from "@/theme";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import LoopIcon from "@mui/icons-material/Loop";

const TrashEntry = ({ customDomain, mutationDeleteCustomDomain }) => {
  return (
    <Tooltip
      title="Remove this domain. All links attached to it will be reset to the default domain."
      placement="top"
    >
      <IconButton
        onClick={() => {
          mutationDeleteCustomDomain({
            variables: {
              id: customDomain.id,
            },
          });
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

const VerifyEntry = ({ customDomain, mutationVerifyCustomDomain, verifyData }) => {
  const rotateIcon = {
    animation: "spin 1s linear infinite",
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
  }

  return (
    <Tooltip
      title="Verify this domain. We'll test if the domain is correctly configured."
      placement="top"
    >
      <IconButton
        onClick={() => {
          mutationVerifyCustomDomain({
            variables: {
              id: customDomain.id,
            },
          });
        }}
      >
        <LoopIcon sx={verifyData.loading ? rotateIcon : {}} />
      </IconButton>
    </Tooltip>
  );
};

export function CustomDomainState({
  customDomain,
  setSuccess,
  setError,
  refetch,
}) {
  const { i18n } = useTranslation(["workspace", "misc"]);
  const [mutationVerifyCustomDomain, verifyData] = useMutation(
    VERIFY_WORKSPACE_DOMAIN
  );

  const [mutationDeleteCustomDomain, deleteData] = useMutation(
    DELETE_WORKSPACE_DOMAIN
  );

  useEffect(() => {
    if (deleteData.data) {
      setSuccess("Domain deleted");
      refetch();
    }
    if (deleteData.error) setError(deleteData.error);
  }, [deleteData.data, deleteData.error]);

  useEffect(() => {
    if (verifyData.data) {
      setSuccess("Domain verification refreshed");
      refetch();
    }
    if (verifyData.error) {
      setError(`${customDomain.name} ${verifyData.error.message}`);
      refetch();
    }
  }, [verifyData.data, verifyData.error]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!customDomain.verified) {
        mutationVerifyCustomDomain({
          variables: {
            id: customDomain.id,
          },
        });
      }
    }, VERIFY_DOMAIN_INTERVAL);

    return () => clearInterval(interval);
  }, [customDomain, mutationVerifyCustomDomain]);

  const VerifiedDomain = () => {
    return (
      <Tooltip
        title={`This domain is verified and point to our servers. It was last checked ${relativeDate(
          customDomain.lastCheckedAt,
          i18n
        )} `}
        placement="top"
      >
        <div className="p-1 ml-0 mr-0 border-2 min-w-fit border-primary rounded-md border-solid w-48 flex justify-start items-center">
          <CheckIcon className="text-primary" />
          <span>{customDomain.name}</span>
        </div>
      </Tooltip>
    );
  };
  const NonVerifiedDomain = () => {
    return (
      <Tooltip
        title="Your domain does not seem to be pointing to our servers. Please verify your configuration."
        placement="top"
      >
        <div className="p-1 ml-0 mr-0 border-2 min-w-fit border-red rounded-md border-solid w-48 flex justify-start items-center">
          <CloseIcon className="text-red" />
          <span>{customDomain.name}</span>
        </div>
      </Tooltip>
    );
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center" className="mt-2">
      {customDomain.verified ? <VerifiedDomain /> : <NonVerifiedDomain />}
      <VerifyEntry
        customDomain={customDomain}
        verifyData={verifyData}
        mutationVerifyCustomDomain={mutationVerifyCustomDomain}
      />
      <TrashEntry
        customDomain={customDomain}
        mutationDeleteCustomDomain={mutationDeleteCustomDomain}
      />
    </Stack>
  );
}

export function CustomDomains({ setSuccess, setError }) {
  const { t } = useTranslation(["workspace", "misc"]);
  const [customDomains, setCustomDomains] = useState<
    ListeCustomDomainsQuery["ListCustomDomains"]
  >([]);
  const [instructionOpen, setInstructionOpen] = useState<boolean>(false);
  const [mutationCreateCustomDomain, { data, error, loading }] = useMutation(
    CREATE_WORKSPACE_DOMAIN
  );
  const listWorkspacesDomains = useQuery<ListeCustomDomainsQuery>(
    LIST_WORKSPACE_DOMAINS
  );

  useEffect(() => {
    if (listWorkspacesDomains.data) {
      setCustomDomains(listWorkspacesDomains.data.ListCustomDomains);
    }
  }, [listWorkspacesDomains.data]);

  const [newDomain, setNewDomain] = useState<string>("");

  useEffect(() => {
    if (data) {
      setSuccess("Domain added");
      setNewDomain("");
      listWorkspacesDomains.refetch();
    }
    if (error) {
      setError(error);
    }
  }, [data, error]);

  if (listWorkspacesDomains.loading) return <Loading />;

  return (
    <React.Fragment>
      <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <SettingDescription
            title={t("links.workspace-domains")}
            description={t("links.workspace-domains-description")}
          />
          <Grid item xs={12} md={8} sx={{ mb: 2, mt: 2 }}>
            <Paper
              sx={{ p: 2 }}
              className="border border-solid border-greyish shadow-none"
            >
              {customDomains &&
                customDomains.map((domain) => (
                  <CustomDomainState
                    key={domain?.id}
                    customDomain={domain}
                    refetch={listWorkspacesDomains.refetch}
                    setSuccess={setSuccess}
                    setError={setError}
                  />
                ))}
              {customDomains && customDomains.length > 0 ? (
                <Divider variant="middle" sx={{ mb: 2, mt: 2 }} />
              ) : (
                <></>
              )}
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField
                  size="small"
                  autoComplete="domain"
                  placeholder="mydomain.com"
                  name="domain"
                  value={newDomain}
                  onChange={(event) => {
                    setNewDomain(event.target.value);
                  }}
                  required
                  id="domain"
                  label={t("links.domain-field")}
                />
                <Button
                  disabled={!newDomain || loading}
                  variant="contained"
                  onClick={() => {
                    mutationCreateCustomDomain({
                      variables: {
                        name: newDomain,
                      },
                    });
                  }}
                >
                  Add domain
                </Button>
              </Stack>
              <div className="text-sm mt-2">
                <CustomDomainInstructionsDialog
                  open={instructionOpen}
                  setOpen={setInstructionOpen}
                />
                You must configure your domain to work with our system.{" "}
                <a
                  href="#"
                  onClick={() => {
                    setInstructionOpen(true);
                  }}
                >
                  Please follow our configuration instructions.
                </a>
                .
              </div>
            </Paper>
          </Grid>
        </Stack>
      </Grid>
    </React.Fragment>
  );
}

import cnameInstruction from "@/assets/cname-instruction.png";
import { ListeCustomDomainsQuery } from "@/generated/graphql/graphql";
import { relativeDate } from "@/helpers/time";
import { t } from "i18next";

export function CustomDomainInstructionsDialog({ open, setOpen }) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      sx={dialogStyle}
      maxWidth="xl"
      onClose={() => {
        setOpen(false);
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>
        Domain configuration
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Alert severity="info">
            Access the DNS settings of your domain and add a{" "}
            <strong>CNAME</strong> record pointing to{" "}
            <strong>domains.linkbreakers.com</strong>
          </Alert>
        </DialogContentText>
        <DialogContentText id="alert-dialog-slide-description" className="mt-2">
          <img className="p-5" src={cnameInstruction} width="100%" />
          This is an example of how to configure a CNAME record for the
          subdomain "qrcode"
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function SettingDescription({ title, description, chip = "" }) {
  const { t } = useTranslation(["workspace", "misc"]);

  const chipElement = (
    <Tooltip title={t("workspace.gdpr")} placement="top">
      <span className="bg-primary rounded-lg text-sm p-1 text-white">
        {chip}
      </span>
    </Tooltip>
  );

  return (
    <Grid item xs={12} md={4} sx={{ mb: 2, mt: 2 }}>
      <Typography component="h3" sx={{ fontWeight: "bold" }}>
        {title} {chip ? chipElement : <></>}
      </Typography>
      <Typography component="p" sx={{ color: "grey" }}>
        {description}
      </Typography>
    </Grid>
  );
}
