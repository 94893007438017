import {
  clearIdentity,
  getIdentity,
  getRole,
  getWorkspaceID,
  isGuest,
  setClientOrigin,
  setFake,
  setSensitiveData,
  unsetFake,
  unsetSensitiveData,
  withDashboardAccess,
} from "@/helpers/identity";
import PageError from "@/pages/error";
import Preflight from "@/preflight";
import { ApolloProvider } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  matchRoutes,
  useLocation,
  useNavigationType,
  useRouteError,
} from "react-router-dom";

import { withoutDashboardAccess } from "@/helpers/identity";
import AuthenticationResetPassword from "@/pages/authentication/reset-password";
import AuthenticationSignIn from "@/pages/authentication/sign-in";
import EventsScan from "@/pages/events/scan";
import LegalPrivacyStatement from "@/pages/legal/privacy-statement";
import LegalTermsOfService from "@/pages/legal/terms-of-service";
import NotFound from "@/pages/not-found";
import SignOut from "@/pages/sign-out";
import WorkspaceBilling from "@/pages/workspace/billing";
import WorkspaceDashboardLinks from "@/pages/workspace/dashboard/links";
import WorkspaceDashboardLinksEdit from "@/pages/workspace/dashboard/links/edit";
import WorkspaceDashboardLinksEditBase from "@/pages/workspace/dashboard/links/edit/base";
import WorkspaceDashboardLinksEditDesign from "@/pages/workspace/dashboard/links/edit/design";
import WorkspaceDashboardLive from "@/pages/workspace/dashboard/live";
import WorkspaceDashboardMembers from "@/pages/workspace/dashboard/members";
import WorkspaceDashboardMembersDevices from "@/pages/workspace/dashboard/members/devices";
import WorkspaceDashboardMembersEdit from "@/pages/workspace/dashboard/members/edit";
import WorkspaceDashboardMembersImport from "@/pages/workspace/dashboard/members/import";
import WorkspaceReports from "@/pages/workspace/dashboard/reports/";
import WorkspaceSettings from "@/pages/workspace/dashboard/settings/";
import WorkspaceSupport from "@/pages/workspace/dashboard/support/";
import WorkspaceSettingsLinkCreate from "@/pages/workspace/links/create";
import WorkspaceSettingsLinkDesign from "@/pages/workspace/links/design";
import WorkspaceSettingsLinkNew from "@/pages/workspace/links/new";
import WorkspaceSignUp from "@/pages/workspace/sign-up";
import WorkspaceSignUpWizard from "@/pages/workspace/sign-up/wizard";
import WorkspaceSubscription from "@/pages/workspace/subscription/";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import React from "react";

import MyselfCredentials from "@/pages/myself/credentials";
import MyselfName from "@/pages/myself/name";
import createApolloClient from "./apollo-client";
import { eventBusReceive } from "./helpers/event-bus";
import Layout from "./pages/workspace/dashboard/layout/dashboard";

import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import GlobalSmallLoading from "./components/global-small-loading";
import { rawURLAssign } from "./helpers/navigation";
import "./i18n";

const env = import.meta.env.VITE_ENV;

if (env !== "development") {
  Sentry.init({
    dsn: "https://e281e8e58d90023be9d92e5b83177291@o4508052616642560.ingest.us.sentry.io/4508063845253120",
    // we ignore all apollo errors since
    // we process them manually from apollo-client.tsx
    ignoreErrors: [/ApolloError/],
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/api\.linkbreakers\.io/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function App() {
  const { t } = useTranslation("misc");
  const [online, setOnline] = useState<boolean>(true);
  const [forbidden, setForbidden] = useState<boolean>(false);

  // from the landing we could some params such as offer and stuff
  const { searchParams } = new URL(window.location.href);
  if (searchParams.get("offer")) {
    setClientOrigin("originOffer", searchParams.get("offer") as string);
  }

  if (searchParams.get("ref")) {
    setClientOrigin("originRef", searchParams.get("ref") as string);
  }

  if (searchParams.get("blur") === "on") {
    setSensitiveData();
  }

  if (searchParams.get("blur") === "off") {
    unsetSensitiveData();
  }

  if (searchParams.get("fake") === "on") {
    setFake();
  }

  if (searchParams.get("fake") === "off") {
    unsetFake();
  }

  useEffect(() => {
    window.addEventListener("online", () => {
      setOnline(true);
    });
    window.addEventListener("offline", () => {
      setOnline(false);
    });
  }, []);

  const env = import.meta.env.VITE_ENV;
  const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN;
  const debug = env === "development";

  mixpanel.init(mixpanelToken, {
    debug,
    // for now we want to track page views
    // but it adds a ton of events so
    // we may want to deactivate it eventually
    track_pageview: true,
    persistence: "localStorage",
  });

  eventBusReceive("network-error", (payload) => {
    // here we can check all types of network error in details
    // and react accordingly before anything else is loaded
    if (payload.statusCode === 403) setForbidden(true);
  });

  if (!online) {
    return <PageError error={t("error.offline")} />;
  }

  if (forbidden) {
    clearIdentity();
    return <PageError error={t("error.forbidden")} />;
  }

  loadDevMessages();
  loadErrorMessages();

  const redirect = (url: string) => {
    rawURLAssign(url);
    // we show nothing because
    // it'll redirect the page directly
    return <GlobalSmallLoading />;
  };

  const requiredDomain: string = import.meta.env.VITE_APP_REQUIRED_DOMAIN;
  if (requiredDomain) {
    if (!window.location.href.startsWith(requiredDomain)) {
      return redirect(requiredDomain);
    }
  }

  const guestRoutes = [
    "/workspace/sign-up",
    "/workspace/sign-up/wizard",
    "/authentication/sign-in",
  ];

  const workspaceRoutes = (url: string): boolean => {
    if (guestRoutes.includes(url)) return false;
    if (url.startsWith("/workspace/")) return true;
    if (url.startsWith("/myself/")) return true;
    return false;
  };

  const workspaceRoot = "/workspace/dashboard/live";
  const memberRoot = `/member/${getWorkspaceID()}/welcome`;
  const mainGuestRoot = "/workspace/sign-up";
  const url = window.location.pathname;

  if (withDashboardAccess(getRole())) {
    if (guestRoutes.includes(url)) {
      return redirect(workspaceRoot);
    }
  } else {
    if (workspaceRoutes(url)) return redirect(mainGuestRoot);
  }

  if (url === "/") {
    if (withDashboardAccess(getRole())) {
      return redirect(workspaceRoot);
    } else if (withoutDashboardAccess(getRole())) {
      return redirect(memberRoot);
    } else return redirect(mainGuestRoot);
  }

  function ErrorBoundary() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const error: any = useRouteError();

    // we manually sent it out to Sentry
    Sentry.setContext("identity", getIdentity());
    Sentry.captureException(error);

    // we show a generalist message
    // but if we are in development we show a little more
    let extra: JSX.Element = <></>;
    if (env === "development") {
      extra = <Typography color="error">DEV: {error.message}</Typography>;
    }
    return (
      <PageError
        error={t("error.something-went-wrong-please-refresh")}
        extra={extra}
      />
    );
  }

  const client = createApolloClient();
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        {/* This was a global loading but it makes jumps effect so it was removed */}
        <Route index element={<GlobalSmallLoading />} />
        {/* Legal */}
        <Route
          path="legal/privacy-statement"
          errorElement={<ErrorBoundary />}
          element={<LegalPrivacyStatement />}
        />
        <Route
          path="legal/terms-of-service"
          errorElement={<ErrorBoundary />}
          element={<LegalTermsOfService />}
        />
        {/* Workspace */}
        <Route
          path="workspace/subscription"
          errorElement={<ErrorBoundary />}
          element={<WorkspaceSubscription />}
        />
        <Route
          path="workspace/billing"
          errorElement={<ErrorBoundary />}
          element={<WorkspaceBilling />}
        />
        <Route
          path="workspace/dashboard"
          errorElement={<ErrorBoundary />}
          element={<Navigate to="/workspace/dashboard/live" />}
        />
        <Route
          path="workspace/dashboard/live"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <WorkspaceDashboardLive />
            </Layout>
          }
        />
        <Route
          path="workspace/dashboard/links"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <WorkspaceDashboardLinks />
            </Layout>
          }
        />
        <Route
          path="workspace/dashboard/links/:linkID/edit"
          errorElement={<ErrorBoundary />}
          element={<WorkspaceDashboardLinksEdit />}
        />
        <Route
          path="workspace/dashboard/links/:linkID/edit/base"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <WorkspaceDashboardLinksEditBase />
            </Layout>
          }
        />
        <Route
          path="workspace/dashboard/links/:linkID/edit/design"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <WorkspaceDashboardLinksEditDesign />
            </Layout>
          }
        />
        <Route
          path="workspace/dashboard/members"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <WorkspaceDashboardMembers />
            </Layout>
          }
        />
        <Route
          path="workspace/dashboard/members/:memberID/devices"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <WorkspaceDashboardMembersDevices />
            </Layout>
          }
        />
        <Route
          path="workspace/dashboard/members/import"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <WorkspaceDashboardMembersImport />
            </Layout>
          }
        />
        <Route
          path="workspace/dashboard/members/:memberID/edit"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <WorkspaceDashboardMembersEdit />
            </Layout>
          }
        />
        <Route
          path="workspace/dashboard/reports"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <WorkspaceReports />
            </Layout>
          }
        />
        <Route
          path="workspace/dashboard/settings"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <WorkspaceSettings />
            </Layout>
          }
        />
        <Route
          path="workspace/dashboard/support"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <WorkspaceSupport />
            </Layout>
          }
        />
        <Route
          path="workspace/links/new"
          errorElement={<ErrorBoundary />}
          element={<WorkspaceSettingsLinkNew />}
        />
        <Route
          path="workspace/links/create"
          errorElement={<ErrorBoundary />}
          element={<WorkspaceSettingsLinkCreate />}
        />
        <Route
          path="workspace/links/:linkID/design"
          errorElement={<ErrorBoundary />}
          element={<WorkspaceSettingsLinkDesign />}
        />
        <Route
          path="workspace/sign-up"
          errorElement={<ErrorBoundary />}
          element={<WorkspaceSignUp />}
        />
        <Route
          path="workspace/sign-up/wizard"
          errorElement={<ErrorBoundary />}
          element={<WorkspaceSignUpWizard />}
        />
        {/* Myself */}
        {/* This is workspace agnostic, it'll be central configurations of the user itself.
        It can be used by admin as well as simple users */}
        <Route
          path="myself/credentials"
          errorElement={<ErrorBoundary />}
          element={<MyselfCredentials />}
        />
        <Route
          path="myself/name"
          errorElement={<ErrorBoundary />}
          element={<MyselfName />}
        />
        <Route
          path="events/:eventID/scan"
          errorElement={<ErrorBoundary />}
          element={<EventsScan />}
        />
        {/* Authentication */}
        <Route
          path="authentication/reset-password"
          errorElement={<ErrorBoundary />}
          element={<AuthenticationResetPassword />}
        />
        <Route
          path="authentication/sign-in"
          errorElement={<ErrorBoundary />}
          element={<AuthenticationSignIn />}
        />
        <Route
          path="sign-out"
          errorElement={<ErrorBoundary />}
          element={<SignOut />}
        />
        <Route
          path="*"
          errorElement={<ErrorBoundary />}
          element={<NotFound />}
        />
        {/* Legacy routes (kept for old QRCode) equivalent to the entrypoints/qrcode one */}
        <Route
          path="employees/:workspaceID/checking/:linkID"
          element={<EventsScan />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="members/:workspaceID/links/:linkID/entrypoints/qrcode"
          element={<EventsScan />}
          errorElement={<ErrorBoundary />}
        />
      </Route>
    )
  );

  const HelpScout = () => {
    useEffect(() => {
      const MOBILE_SIZE = 768;
      if (window.innerWidth > MOBILE_SIZE && !isGuest()) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.Beacon("init", "45bcab0f-2e6f-4518-b8d7-92d98a15722b");
      }
    }, []);

    return <></>;
  };

  // const Crisp = () => {
  //   useEffect(() => {
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-ignore
  //     window.$crisp = [];
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-ignore
  //     window.CRISP_WEBSITE_ID = "7a753b2d-60dc-4f03-bcfc-cf35e75f133d";

  //     const d = document;
  //     const s = d.createElement("script");
  //     s.src = "https://client.crisp.chat/l.js";
  //     s.async = true;
  //     d.getElementsByTagName("head")[0].appendChild(s);
  //   }, []);

  //   return <></>;
  // };

  return (
    <Sentry.ErrorBoundary>
      <ApolloProvider client={client}>
        <Preflight>
          <HelpScout />
          <RouterProvider router={router} />
        </Preflight>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
