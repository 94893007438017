import { FormInfo } from "@/components/form";
import Loading from "@/components/loading";
import PartialError from "@/components/partial-error";
import { eventBusEmit } from "@/helpers/event-bus";
import { findAndTranslateErrors } from "@/helpers/format";
import { getIdentity } from "@/helpers/identity";
import { rawURLReplace } from "@/helpers/navigation";
import { getDateAndTime } from "@/helpers/time";
import Title from "@/pages/workspace/dashboard/components/title";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Divider, Grid, TextField, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export const GET_LINK = gql`
  query GetLink($id: UUID!) {
    GetLink(id: $id) {
      id
      shortID
      name
      entrypoint
      destination
      designType
    }
  }
`;

const UPDATE_LINK = gql`
  mutation UpdateLink($id: UUID!, $destination: String!, $name: String) {
    UpdateLink(id: $id, destination: $destination, name: $name) {
      id
    }
  }
`;

const DELETE_LINK = gql`
  mutation DeleteLink($id: UUID!) {
    DeleteLink(id: $id)
  }
`;

export default function EditLink() {
  const { t } = useTranslation(["workspace", "misc"]);
  const navigate = useNavigate();
  const [, setID] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [slug, setSlug] = useState<string>("");
  const [entrypoint, setEntrypoint] = useState<string>("");
  const [destination, setDestination] = useState<string>("");
  const [designType, setDesignType] = useState<string>("");
  const { linkID } = useParams();
  const getLink = useQuery(GET_LINK, {
    variables: { id: linkID },
    fetchPolicy: "no-cache",
  });
  const [mutationUpdateLink, { data, error }] = useMutation(UPDATE_LINK);
  const [mutationDeleteLink, deleteLink] = useMutation(DELETE_LINK);

  const isMyself = getIdentity().id === linkID;

  useEffect(() => {
    const possibleErrors = error || deleteLink.error;
    const errorDisplay: string = findAndTranslateErrors({
      error: possibleErrors,
      t,
    });
    let successDisplay: string = "";
    if (data) {
      successDisplay = t("links.edit.updated");
    } else if (deleteLink.data) {
      // in this case we should leave the page entirely
      // since the link does not exist anymore.
      rawURLReplace("/workspace/dashboard/links");
    }

    if (successDisplay) {
      eventBusEmit({ type: "form-success", payload: successDisplay });
    }
    if (errorDisplay) {
      eventBusEmit({ type: "form-error", payload: errorDisplay });
    }
  }, [t, data, error, deleteLink.data]);

  useEffect(() => {
    if (getLink.data) {
      setID(getLink.data.GetLink.id);
      setName(getLink.data.GetLink.name);
      setSlug(getLink.data.GetLink.slug);
      setEntrypoint(getLink.data.GetLink.entrypoint);
      setDestination(getLink.data.GetLink.destination);
      setDesignType(getLink.data.GetLink.designType);

      if (getLink.data.GetLink.name) {
        const pageName: string = getLink.data.GetLink.name;
        eventBusEmit({ type: "page-name", payload: "Edit QR Code" });
      }
    }
  }, [getLink.data, navigate, t]);

  if (getLink.loading) return <Loading />;
  if (getLink.error) {
    return (
      <PartialError error={t("error.page-data-failure", { ns: "misc" })} />
    );
  }

  const handleDeleteLink: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();

    mutationDeleteLink({
      variables: {
        id: linkID,
      },
    });
  };

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    mutationUpdateLink({
      variables: {
        id: linkID,
        name: name === "" ? null : name,
        destination: destination === "" ? null : destination,
      },
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {getLink.data.GetLink.accessRevokedAt ? (
            <FormInfo
              message={t("links.access-revoked-notice", {
                date: getDateAndTime(getLink.data.GetLink.accessRevokedAt),
              })}
            />
          ) : (
            ""
          )}

          {isMyself ? <FormInfo message={t("links.editing-yourself")} /> : ""}

          <Title>{t("links.edit.information-title")}</Title>
          <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
            {t("links.edit.information-description")}
          </Typography>

          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ mb: 2 }}
            spacing={2}
          >
            <Grid item xs={12} md={6} sx={{ mb: 2, mt: 2 }}>
              <Paper
                sx={{ p: 2 }}
                className="border border-solid border-greyish shadow-none"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="off"
                      name="destination"
                      value={destination}
                      onChange={(e) => setDestination(e.target.value)}
                      fullWidth
                      id="destination"
                      label={t("links.edit.destination")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="off"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      fullWidth
                      id="name"
                      label={t("links.edit.name")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Tooltip
                      title="Domains and slug can't be changed after creation."
                      placement="top"
                    >
                      <TextField
                        name="Tracking link"
                        value={entrypoint}
                        fullWidth
                        id="linkEntrypoint"
                        autoComplete="off"
                        disabled
                        label={"Entrypoint"}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Stack>

          <Divider variant="middle" sx={{ mb: 2 }} />

          <Grid item xs={12} md={6} lg={3} sx={{ textAlign: "left" }}>
            <Button
              type="submit"
              onClick={handleSubmit}
              variant="contained"
              sx={{ mb: 2 }}
            >
              {t("links.edit.submit")}
            </Button>
          </Grid>

          <Divider variant="middle" sx={{ mb: 2 }} />

          <Typography component="h2" variant="h6" color="error" gutterBottom>
            {t("links.edit.sensitive-actions-title")}
          </Typography>
          <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
            {t("links.edit.sensitive-actions-description")}
          </Typography>

          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ mb: 2 }}
            spacing={2}
          >
            <Grid item xs={12} md={12} sx={{ mb: 2, mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Tooltip
                    title={t("links.edit.delete-tooltip")}
                    placement="top"
                  >
                    <Button
                      type="submit"
                      color="error"
                      onClick={handleDeleteLink}
                      variant="contained"
                      sx={{ mr: 2 }}
                    >
                      {t("links.edit.delete-button")}
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Stack>

          <Grid item lg={2}></Grid>
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}
