import AstroWondering from "@/components/astro-wondering";
import { Copyright } from "@/components/copyright";
import { lightPageBlockStyle } from "@/styles/styles";
import { ApolloError } from "@apollo/client";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";

export default function NotFound({
  error = new ApolloError({ graphQLErrors: [] }),
}) {
  const { t } = useTranslation("misc");

  // we don't need to translate it because
  // it's just for development debugging
  const displayError =
    import.meta.env.VITE_ENV === "development" ? error?.message : "";

  return (
    <React.Fragment>
      <Container component="main" maxWidth="xs" className={lightPageBlockStyle}>
        <Box
          sx={{
            margin: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <QuestionMarkIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("error.page-not-found")}
          </Typography>
          {displayError}
          <AstroWondering />
        </Box>
      </Container>
      <Copyright sx={{ mt: 5, mb: 5 }} />
    </React.Fragment>
  );
}
